/* src/components/ButtonGroup.css */
.button-groups .custom-button {
    margin: 5px;
  }
  
  @media (max-width: 576px) {
    .button-groups .custom-button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  