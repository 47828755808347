.payment-page {
  background: linear-gradient(270deg, #ff7e5f, #feb47b, #86a8e7, #7f7fd5);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.payment-card {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  padding: 20px;
}

.payment-card .form-label {
  font-weight: bold;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.custom-btn {
  background-color: #343a40;
  border-color: #343a40;
  color: white;
  border-radius: 30px;
  font-weight: bold;
  padding: 10px 20px;
  transition: transform 0.1s ease, background-color 0.1s ease;
}

.custom-btn:hover {
  transform: scale(1.03);
}

.user-agreement {
  background-color: #d1deeb;
  border-color: #343a40;
  color: white;
  border-radius: 30px;
  font-weight: bold;
  padding: 10px 20px;
  text-decoration: none;
  transition: transform 0.1s ease, background-color 0.1s ease;
}

.user-agreement:hover {
  transform: scale(1.03);
}

.popup__inner-main-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.1818181818;
  color: #343a40;
  margin-bottom: 14px;
}
.popup__inner-list {
  font-size: 18px;
  line-height: 126.9%;
  -webkit-font-feature-settings: "pnum" on, "lnum" on;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #343a40;
  margin-bottom: -15px;
}
.popup__inner-item {
  margin-bottom: 15px;
}

@media (max-width: 767.98px) {
  .order-md-1, .order-md-2 {
    order: unset !important;
  }

  .button-group {
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }

  .user-agreement {
    margin-top: 20px;
  }
}
@media (max-width: 1200px) {
  .popup__inner-main-title {
    font-size: calc(18px + 2*((100vw - 320px) / 880));
  }
  .popup__inner-item {
    margin-bottom: calc(10px + 5*((100vw - 320px) / 880));
  }
}
@media (max-width: 1200px) {
  .popup__inner-list {
    margin-bottom: calc(-10px + -5*((100vw - 320px) / 880));
  }
  .popup__inner-list {
    font-size: calc(15px + 3*((100vw - 320px) / 880));
  }
}