/* src/index.css */
body {
  background-color: #f8f9fa;
}

.custom-button {
  width: 100px;
  text-align: center;
}

@media (max-width: 768px) {
  .custom-button {
    width: 80px;
  }
}

.container {
  max-width: 600px;
}

.card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
}
