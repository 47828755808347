/* src/components/Home.css */
.info-card {
    width: 100%;
    max-width: 500px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .api-info {
    margin-bottom: 20px;
  }
  
  .info-label {
    font-weight: bold;
    color: #555;
  }
  
  .info-value {
    font-size: 1.2rem;
    color: #000;
  }
  
  .subscription-list {
    list-style: none;
    padding: 0;
  }
  
  .subscription-type {
    font-weight: bold;
    color: #555;
  }
  
  .subscription-value {
    font-size: 1.1rem;
    color: #000;
  }
  