/* ChatGPT.css */
.chat-page {
    background: linear-gradient(270deg, #ff7e5f, #feb47b, #86a8e7, #7f7fd5);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .chat-container {
    /* margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%); */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .chat-card {
    box-shadow: 0 8px 32px rgba(243, 0, 0, 1);
    width: 400px; /* Full width on all screens */
  }
  
  .chat-window {
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    max-height: 100vh;
    height: 400px;
    max-width: 50vw;
    background-color: #f0f0f0; /* Background color for the chat window */
    display: flex;
    flex-direction: column; /* Reverse column direction to show messages from bottom to top */
  }
  
  .message {
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 10px;
    max-width: 100%;
  }
  
  .user-message {
    width: 45%;
    align-self: flex-end;
    background-color: #dcf8c6; /* User message background color */
    color: #333; /* User message text color */
  }
  
  .bot-message {
    max-width: 45%;
    align-self: flex-start;
    background-color: #e0e0e0; /* Bot message background color */
    color: #333; /* Bot message text color */
  }
  
  .message span {
    display: block;
    padding: 10px;
  }
  
  .message-form {
    margin-top: 10px;
  }
  
  .message-form .form-control {
    flex-grow: 1;
    margin-right: 10px;
  }
  
  .message-form button {
    min-width: 80px;
  }
  
  .msg_date {
    float: right;
    padding: 5px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .chat-container {
      padding: 10px;
    }
  
    .chat-card {
      width: 100%;
      max-width: 400px; /* Limit width for smaller screens */
    }
  
    .chat-window {
      height: 90vh; /* Reduce height for smaller screens */
      max-width: 90vw;
    }
  
    .message {
      max-width: 100%; /* Full width for messages on smaller screens */
    }
  }
  